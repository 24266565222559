import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DeviceService} from '../device.service';
import {Device} from '../../model/Device';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {Geo} from '../../model/Geo';
import {InfoLinksComponent} from '../info-links/info-links.component';
import {StatuscolorPipe} from '../statuscolor.pipe';
import {MatSelectChange, MatSliderChange} from '@angular/material';
import {Observable, Subscription} from 'rxjs';
import {Parking} from '../../model/Parking';

const PLAY_ICON = 'play_arrow';
const PAUSE_ICON = 'pause';

@Component({
  selector: 'app-play-controls',
  templateUrl: './play-controls.component.html',
  styleUrls: ['./play-controls.component.css']
})
export class PlayControlsComponent implements OnInit, OnDestroy {
  devices: Device[];
  // @Input() selectedDevice: Device;
  selectedDevice: Device;
  startDateControl: FormControl;
  endDateControl = new FormControl(new Date());
  deviceControl = new FormControl(null);
  parkTimeControl = new FormControl('300');
  min: Date;

  playSpeed = 20;

  geos: Geo[];
  markerPlay: google.maps.Marker;
  infoWindow: google.maps.InfoWindow;
  playPath: google.maps.Polyline;
  playPaths: google.maps.Polyline[] = [];
  alarmPaths: google.maps.Polyline[] = [];
  parkings: google.maps.Marker[] = [];
  mileageTotal = 0.0;

  startDate: Date;
  endDate: Date;

  statusColorPipe: StatuscolorPipe;
  minMovingSpeed: number;

  playIntervalTimer: any;
  playStarted = false;
  queryChanged = false; // listen for start, end date and selected device changes.

  playPauseIcon = PLAY_ICON;

  playIdx = 0;
  deviceSubscription: Subscription;

  constructor(private deviceService: DeviceService) {
    this.statusColorPipe = new StatuscolorPipe();
    this.minMovingSpeed = this.deviceService.minMovingSpeed;
  }

  ngOnInit() {
    this.markerPlay = new google.maps.Marker({
      position: new google.maps.LatLng(6.927556, 79.849409)
    });

    google.maps.event.addListener(this.markerPlay, 'click', () => {
      if (this.infoWindow) {
        this.infoWindow.open(this.deviceService.map, this.markerPlay);
      }
    });


    const startDate = new Date();
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    this.startDateControl = new FormControl(startDate);
    this.min = this.startDateControl.value;

    this.getDevices();
    this.getSelectedDevice();
    this.onChanges();
  }

  onChanges() {
    this.startDateControl.valueChanges.subscribe(value => {
      this.min = new Date(value);
      this.queryChanged = true;
    });

    this.endDateControl.valueChanges.subscribe(value => {
      this.queryChanged = true;
    });

    this.parkTimeControl.valueChanges.subscribe(value => {
      this.queryChanged = true;
    });
  }

  getDevices() {
    this.deviceSubscription = this.deviceService.getDevices().subscribe(devices => {
      this.devices = devices;
      console.log('play all devices');
    });
  }

  getSelectedDevice() {
    this.deviceService.getSelectedDevice().subscribe(device => {
      this.selectedDevice = device;
      this.deviceControl.setValue(this.selectedDevice);
      console.log('selected device ' + device.vehicle);
    });
  }

  compareFn(x: Device, y: Device): boolean {
    return x && y ? x._id === y._id : x === y;
    // return x.imei === y.imei;
  }

  onSelectedDeviceChanged(event: MatSelectChange) {
    console.log('changing selected devices');
    this.deviceService.setSelectedDevice(event.value);
    this.queryChanged = true;
  }

  playback() {
    if (this.playIdx === 0 || this.queryChanged) { // fresh start
      this.showPath();
    } else if (this.playStarted) { // pause
      this.pause();
    } else if (this.playIdx === this.geos.length) { // startover
      // :TODO monitor device changes
      this.playIdx = 0;
      this.mileageTotal = 0.0;
      this.startPlay();
    } else if (!this.playStarted) { // resume
      this.startPlay();
    }

  }

  showPath() { // init playback
    if (!this.selectedDevice) {
      alert('select a device');
      return;
    }

    this.queryChanged = false; // started to query current selection.

    this.geos = null;
    if (this.playPaths) { // remove all paths
      this.playPaths.forEach(path => {
        path.setMap(null);
      });
      this.playPaths = [];
    }

    if (this.alarmPaths) { // remove all Alarm (overspeed) paths
      this.alarmPaths.forEach(path => {
        path.setMap(null);
      });
      this.alarmPaths = [];
    }

    if (this.parkings) {
      this.parkings.forEach(park => {
        park.setMap(null);
      });
      this.parkings = [];
    }


    this.playIdx = 0;
    this.playStarted = false;


    clearInterval(this.playIntervalTimer);
    this.markerPlay.setMap(null);
    this.mileageTotal = 0.0;

    const startDate = this.startDateControl.value;
    this.startDate = new Date(startDate); // avoid formcontrol change
    this.endDate = this.endDateControl.value;
    // const diff = (endDate.getTime() -  startDate.getTime()) / (1000 * 3600 * 24);
    this.getPlayData();
  }

  private getPlayData() {
    const startTime = this.startDate.getTime();
    console.log('start: ' + this.startDate);
    this.startDate.setDate(this.startDate.getDate() + 1);
    let endTime;

    if (this.startDate < this.endDate) {
      endTime = this.startDate.getTime();
      console.log('end: ' + this.startDate);
    } else {
      endTime = this.endDate.getTime();
      console.log('end: ' + this.endDate);
    }

    this.deviceService
      .getPlayPath(this.selectedDevice._id, startTime, endTime)
      .subscribe((geos: Geo[]) => {
        if (this.geos) {
          this.geos.push.apply(this.geos, geos);
        } else {
          this.geos = geos;
        }

        console.log(geos);

        const wayPoints = [];
        let alarmPaths = [];
        let alarmPath = null;

        geos.forEach(geo => {
          wayPoints.push(new google.maps.LatLng(geo.loc[0], geo.loc[1]));

          if (geo.sp > this.selectedDevice.speed_limit) {
            if (!alarmPath) {
              alarmPath = [];
            }
            alarmPath.push(new google.maps.LatLng(geo.loc[0], geo.loc[1]));
          } else {
            if (alarmPath) {
              alarmPaths.push(alarmPath);
              alarmPath = null;
            }
          }
        });

        if (alarmPath) {
          alarmPaths.push(alarmPath);
          alarmPath = null;
        }

        const playPath = new google.maps.Polyline({ // create a polyline for data received.
          path: wayPoints,
          geodesic: true,
          strokeColor: '#00695f',
          strokeOpacity: 1.0,
          strokeWeight: 5,
          map: this.deviceService.map
        });

        this.playPaths.push(playPath);

        alarmPaths.forEach( alarm_path  => {
          const alarmPoly = new google.maps.Polyline({ // create a polyline for data received.
            path: alarm_path,
            geodesic: true,
            strokeColor: '#ff000f',
            strokeOpacity: 1.0,
            strokeWeight: 5,
            map: this.deviceService.map
          });

          this.alarmPaths.push(alarmPoly);
        });

        alarmPaths = [];

        if (this.parkTimeControl.value > 0) {
          this.deviceService.getParkings(this.selectedDevice._id, startTime, endTime, this.parkTimeControl.value).subscribe( parkings => {
            parkings.forEach( parking => {
              const parkMarker = new google.maps.Marker({
                position: new google.maps.LatLng(parking.loc[0], parking.loc[1]),
                map: this.deviceService.map,
                icon: {url: './assets/images/icons/parking.png'}
              });

              this.parkings.push(parkMarker);

              const parkingInfo = new google.maps.InfoWindow({
                content: this.createParkingInfoWindowContent(parking, this.parkings.indexOf(parkMarker) + 1)
              });

              google.maps.event.addListener(parkMarker, 'click', () => {
                parkingInfo.open(this.deviceService.map, parkMarker);
              });

            });
          });
        }

        if (!this.playStarted && this.geos.length > 0) { // start animating
          this.startPlay();
        }
        if (this.startDate < this.endDate) { // get play data for next day
          this.getPlayData();
        } else if (this.geos.length === 0) {
          alert('No history record available!');
        }
      });



  }

  private startPlay() {
    this.playStarted = true;
    this.playPauseIcon = PAUSE_ICON;
    this.markerPlay.setMap(this.deviceService.map);
    const playSpeed = 1000 / this.playSpeed;

    if (!this.infoWindow && this.geos.length > 0) { // create infowindow first time
      this.createNewInfoWindow(this.geos[0]);
    }

    this.playIntervalTimer = setInterval(
      () => {
        if (this.playIdx !== this.geos.length) {
          const geo = this.geos[this.playIdx];
          this.markerPlay.setPosition(new google.maps.LatLng(geo.loc[0], geo.loc[1]));
          this.updateInfoWindow(geo);
          this.playIdx++;
        } else {
          this.playStarted = false;
          clearInterval(this.playIntervalTimer);
          this.playPauseIcon = PLAY_ICON;
        }
      }
      , playSpeed); // playSpeed = ticks per second.
  }

  private pause() {
    if (this.playStarted) {
      this.playStarted = false;
      clearInterval(this.playIntervalTimer);
      this.playPauseIcon = PLAY_ICON;
    }
  }

  stop() {
    this.pause();
    this.mileageTotal = 0.0;
    this.playIdx = 0;

    this.geos = null;
    if (this.playPaths) { // remove all paths
      this.playPaths.forEach(path => {
        path.setMap(null);
      });
      this.playPaths = [];
    }

    if (this.alarmPaths) { // remove all Alarm (overspeed) paths
      this.alarmPaths.forEach(path => {
        path.setMap(null);
      });
      this.alarmPaths = [];
    }

    if (this.parkings) {
      this.parkings.forEach(park => {
        park.setMap(null);
      });
      this.parkings = [];
    }


    this.markerPlay.setMap(null);
  }

  private getPath() {
    const startTime = this.startDate.getTime();
    this.startDate.setDate(this.startDate.getDate() + 1);

    if (this.startDate < this.endDate) {
      const endTime = this.startDate.getTime();
    } else {
      const endTime = this.endDate.getTime();
    }


    // at next
    if (this.startDate < this.endDate) {
      this.getPath();
    }
  }

  playSpeedChanged(matSliderChange: MatSliderChange) {
    console.log('slide to :' + matSliderChange.value);
    this.playSpeed = matSliderChange.value;

    if (this.playStarted) {
      this.pause();
      this.startPlay();
    }
  }


  private createNewInfoWindow (geo: Geo) {
    if (!this.infoWindow) {
      this.infoWindow = new google.maps.InfoWindow({
        content: this.createInfoWindowContent(geo)
      });
    }

    this.infoWindow.open(this.deviceService.map, this.markerPlay);
  }

  private updateInfoWindow (geo: Geo) {
    this.infoWindow.setContent(this.createInfoWindowContent(geo));
  }

  private createInfoWindowContent(geo: Geo) {
    this.mileageTotal += geo.mi; // add mileage
    const contents = `<div class="info-container play-info">
                        <p class="vehicle-name">Vehicle: <span>${this.selectedDevice.vehicle}</span></p> <hr class="infow-hr"/>
                        <div>
                          <div class="speed-container"><label>speed: </label><span class="info">${geo.sp}</span> Km/h</div>
                          <div class="status-container">
                            <div class="status-circle bg-${this.statusColorPipe.transform(this.selectedDevice.status,
                              geo.sp, this.minMovingSpeed, this.selectedDevice.speed_limit)}"></div>
                          </div>
                        </div>
                        <div class="alarms color-alarm dis-none">
                          <p>${this.deviceService.getAlarms(geo, this.selectedDevice)}</p>
                        </div>
                        <p>mileage: <span class="info">${this.mileageTotal.toFixed(2)}</span> Km</p>
                        <p>time: <span>${this.deviceService.formatDate(geo.dt)}</span></p>
                        <p>geo : <span class="geo-label">${geo.loc[0]}, ${geo.loc[1]}</span></p>
                      </div>`;

    return contents;
  }

  private createParkingInfoWindowContent(parking, parkingNumber) {
    const duration = parking.duration;
    const days = parseInt((duration / (3600 * 24)) + '', 10);

    const hours = parseInt(((duration / 3600) % 24) + '', 10);
    const minutes = parseInt(((duration / 60) % 60) + '', 10);
    const seconds = duration % 60;

    const formattedDuration = (days > 0 ? days + ' day(s) - ' : '') +
      (hours > 0 ? hours + ' h: ' : '') + (minutes > 0 ? minutes + ' m: ' : '')
      + (seconds > 0 ? seconds + ' s' : '');

    const contents = `<div class="info-container play-info">
                        <p class="vehicle-name">Parking: <span>${parkingNumber}</span></p> <hr class="infow-hr"/>
                        <p><strong>start: </strong><span>${this.deviceService.formatDate(parking.start)}</span></p>
                        <p><strong>end  : </strong><span>${this.deviceService.formatDate(parking.end)}</span></p>
                        <p><strong>location : </strong><span>${parking.loc[0]}, ${parking.loc[1]}</span></p>
                        <p><strong>duration : </strong><span class="geo-label">${formattedDuration}</span></p>
                      </div>`;
    return contents;
  }

  exit() {
    this.stop();
    this.deviceService.setPlayMode(false);

    this.deviceSubscription.unsubscribe();
    // :TODO unsubscribe this.getDevices();
  }

  private oldPlay() {
    this.deviceService
      .getPlayPath('', this.startDateControl.value, this.endDateControl.value)
      .subscribe((data: Geo[]) => {
        this.geos = data;
        console.log('Data requested ... ');
        console.log(this.geos);

        const wayPoints = [];
        this.geos.forEach(geo => {
          wayPoints.push(new google.maps.LatLng(geo.loc[0], geo.loc[1]));
        });

        this.playPath = new google.maps.Polyline({
          path: wayPoints,
          geodesic: true,
          strokeColor: '#00695f',
          strokeOpacity: 1.0,
          strokeWeight: 5
        });

        this.createNewInfoWindow(this.geos[0]); // create info window to display status

        this.playPath.setMap(this.deviceService.map);
        this.markerPlay.setMap(this.deviceService.map);

        let idx = 0;
        this.playIntervalTimer = setInterval(
          () => {
            if (idx !== this.geos.length) {
              const geo = this.geos[idx];
              let dtime = (geo.dt).toString();
              dtime = dtime.substring(11, 19);
              const label = dtime + ' [' + geo.sp + ']';

              this.markerPlay.setPosition(new google.maps.LatLng(geo.loc[0], geo.loc[1]));
              // this.markerPlay.setLabel(label);

              this.updateInfoWindow(geo);
              idx++;
            }
          }
          , 100); // 50

        // this.geos.forEach(geo => {
        //
        // });


        // this.marker.setPosition(new google.maps.LatLng(this.device.loc[0], this.device.loc[1]));
      });
  }

  ngOnDestroy(): void {
    this.stop();
    this.deviceSubscription.unsubscribe();
  }
}



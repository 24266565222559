import { Component, OnInit } from '@angular/core';
import {BusinessService} from '../business.service';
import {Business} from '../../model/Business';
import {DeviceService} from '../device.service';
import {FormControl} from '@angular/forms';
import {Device} from '../../model/Device';
import {MatAutocompleteSelectedEvent} from '@angular/material';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-business-tree',
  templateUrl: './business-tree.component.html',
  styleUrls: ['./business-tree.component.css']
})
export class BusinessTreeComponent implements OnInit {
  businesses: Business[];
  selectedBusiness: Business;
  searchControl = new FormControl();
  filteredOptions: Observable<Business[]>;

  constructor(private businessService: BusinessService, private deviceService: DeviceService) { }

  ngOnInit() {
    this.initialaize();
  }

  initialaize(){
    this.businessService.getBusinessTree().subscribe( businesses => {
      this.businesses = businesses;

      this.filteredOptions = this.searchControl.valueChanges
        .pipe(
          startWith<string | Business>(''),
          map(value => typeof value === 'string' ? value : value.business_name),
          // map(name => name ? this._filter(name) : this.businesses.slice(1))
          map(name => name ? this._filter(name) : null)
        );
    });
  }

  setSelectedBusiness(business: Business) {
    this.deviceService.setSelectedBusiness(business);
    this.selectedBusiness = business;
    // :TODO clear markers array in live monitor
  }

  displayFn(business?: Business): string | undefined {
    // if (device) {
    //   this.setSelectedDevice(device);
    // }
    return business ? business.business_name : undefined;
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.setSelectedBusiness(event.option.value);
  }

  private _filter(bizName: string): Business[] {
    const filterValue = bizName.toLowerCase();

    return this.businesses.filter(business => business.business_name.toLowerCase().indexOf(filterValue) > -1);
  }
}

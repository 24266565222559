/// <reference types="@types/googlemaps" />
import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Injector,
  NgZone,
  OnInit,
  ViewChild
} from '@angular/core';
import {DeviceService} from '../device.service';
import {Device} from '../../model/Device';
import {StatuscolorPipe} from '../statuscolor.pipe';
import {StatuscasePipe} from '../statuscase.pipe';
import {InfoLinksComponent} from '../info-links/info-links.component';
import {Subscription} from 'rxjs';

declare var InfoBox: any;

const MAP_ZOOM_LEVEL = 16; // zoom to on marker click
const MAP_LOW_ZOOM_LEVEL = 10; // zoom on marker click, status/infowindow if zoom level is less than this.

@Component({
  selector: 'app-live-monitor',
  templateUrl: './live-monitor.component.html',
  styleUrls: ['./live-monitor.component.css'],
  // providers: [DeviceService]
})


export class LiveMonitorComponent implements OnInit {
  @ViewChild('map') gmapElement: any;
  map: google.maps.Map;
  markers: google.maps.Marker[];
  marker: google.maps.Marker;
  infoWindow: google.maps.InfoWindow;
  deviceIds: string[];
  devices: Device[];
  device: Device;
  statusColorPipe: StatuscolorPipe;
  // statusCasePipe: StatuscasePipe;
  minMovingSpeed: number;
  intervalId: any;
  compRef: ComponentRef<InfoLinksComponent>;
  isPlayMode: boolean;
  monitorSubscription: Subscription;

  startTime: number;

  car = './assets/cart.svg';

  constructor(private deviceService: DeviceService, private elRef: ElementRef,
  private injector: Injector,
  private resolver: ComponentFactoryResolver,
  private appRef: ApplicationRef,
  private zone: NgZone) {
    this.statusColorPipe = new StatuscolorPipe();
    // this.statusCasePipe = new StatuscasePipe();
    this.minMovingSpeed = 7;
  }

  async initMap() {
    const mapProp = {
      center: new google.maps.LatLng(6.927556, 79.849409),
      zoom: 7,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = await new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    this.deviceService.map = this.map;

    // const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    // this.map = new Map(document.getElementById("map") as HTMLElement, {
    //   center: { lat: 6.927556, lng: 79.849409 },
    //   zoom: 8,
    // });
  }

  ngOnInit() {
    this.markers = [];
    this.initMap();

    // const mapProp = {
    //   center: new google.maps.LatLng(6.927556, 79.849409),
    //   zoom: 7,
    //   mapTypeId: google.maps.MapTypeId.ROADMAP
    // };
    //
    // this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    // this.deviceService.map = this.map;

    // this.map.addListener()

    // this.marker = new google.maps.Marker({
    //   map: this.map,
    //   // label: 'CAT-7000',
    //   title: 'AA car',
    //   position: new google.maps.LatLng(6.927556, 79.849409),
    //   icon: {
    //     // url: './assets/images/g.png',
    //     url: './assets/images/2.svg',
    //     size: new google.maps.Size(30, 30),
    //     // origin: new google.maps.Point(0, 10),
    //     // anchor: new google.maps.Point(0, 10),
    //     scaledSize: new google.maps.Size(30, 30),
    //     rotation: 90
    //     // path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    //     // scale: 4,
    //   },
    // });
    // this.startTime = new Date().getTime();
    this.monitorSelectedBusiness();
    this.getSelectedDevice();
    this.checkPlayMode();

    // this.checkData();
    // this.intervalId =  setInterval(() => {this.checkData(); }, 5000);
    this.showStatusInfoWindow();
  }

  checkPlayMode() {
    this.deviceService.getPlayMode().subscribe(playMode => {
      this.isPlayMode = playMode;

      if (this.isPlayMode) {
        clearInterval(this.intervalId);
        this.intervalId = null;
        for (const imei of Object.keys(this.markers)) {
          this.markers[imei].get('vehicle_name').setMap(null);
          this.markers[imei].setMap(null);
        }
        this.infoWindow = null;
      } else { // monitor mode
        this.markers = [];
        this.checkData();
        this.intervalId =  setInterval(() => {this.checkData(); }, 5000);
        // for (const imei of Object.keys(this.markers)) {
        //   this.markers[imei].setMap(this.map);
        // }
      }
    });

    // :TODO clear markers, unsubscribe live
  }

  setNowTime() {

  }

  getSelectedDevice() {
    this.deviceService.getSelectedDevice().subscribe( device => {
      this.device = device;
      if (this.device && this.markers.length > 0) {
        const marker = this.markers[device.imei];
        this.map.panTo(marker.getPosition());
        if (this.map.getZoom() < MAP_LOW_ZOOM_LEVEL) {
          this.map.setZoom(MAP_ZOOM_LEVEL);
        }
      }
    });
  }

  monitorSelectedBusiness() {
    this.deviceService.getChangedBusiness().subscribe(business => {
      // clear interval, markers, checkdata
      this.monitorSubscription.unsubscribe();
      clearInterval(this.intervalId);
      if (this.devices) {
        this.devices.forEach(device => {
          const marker = this.markers[device.imei];
          marker.setMap(null);
          marker.get('vehicle_name').setMap(null);
        });

        this.setSelectedDevice(null);
        this.markers = [];
        this.devices = [];
      }
      this.infoWindow = null;

      this.checkData();
      this.intervalId =  setInterval(() => {this.checkData(); }, 5000);
    });
  }


  showStatusInfoWindow() {
    this.deviceService.getStatusShowDevice().subscribe( device => {
      const marker = this.markers[device.imei];
      if (this.map.getZoom() < MAP_LOW_ZOOM_LEVEL) {
        this.map.setZoom(MAP_ZOOM_LEVEL);
      }

      this.showInfoWindow(marker, device);
    });
  }

  showInfoWindow(marker: google.maps.Marker, device: Device) {
    const vehicleName = marker.get('vehicle_name');
    vehicleName.setMap(null);

    if (this.infoWindow) { // show previous info marker's label back
      const markerPre = this.markers[this.infoWindow.get('imei')];
      markerPre.get('vehicle_name').setMap(this.map);
      this.infoWindow.close();
      markerPre.set('infoWindow', null);
    }
    this.infoWindow = null;

    const infoWindow = new google.maps.InfoWindow({
      content: this.createInfoWindowContent(device)
    });
    infoWindow.open(this.map, marker);
    infoWindow.set('imei', device.imei);
    marker.set('infoWindow', infoWindow);

    google.maps.event.addListener(infoWindow, 'closeclick', () => {
      const markerInfoClose = this.markers[infoWindow.get('imei')];
      markerInfoClose.get('vehicle_name').setMap(this.map); // show it's label back
      markerInfoClose.set('infoWindow', null);
      this.infoWindow = null;
    });

    // infoWindow.addListener('click', (e) => {
      // we need to run it in angular2 zone
      // this.zone.run(() => this.onMarkerClick(marker, e));
    // });

    // google.maps.event.addListenerOnce(this.infoWindow, 'domready', () => {
    //   document.getElementById('play').addEventListener('click', () => {
    //     this.test();
    //   });
    // });

    this.infoWindow = infoWindow;
  }

  setSelectedDevice (device: Device) {
    this.device = device;
    this.deviceService.setSelectedDevice(device);
  }

  checkData() {
    if (!this.deviceService.getSelectedBusiness()) {
      return;
    }
    console.log('checking.');
    this.monitorSubscription = this.deviceService
      .getStatus()
      .subscribe(devices => {

        if (this.isPlayMode)  {return; }

        this.devices = devices;
        // console.log('Data requested ... ');
        // this.device = this.devices[0];
        // console.log(this.device);
        //
        // let dtime = (this.device.dt).toString();
        // dtime = dtime.substring(11, 19);
        // const label = dtime + ' [' + this.device.sp + ']';

        // if(this.deviceIds.indexOf())

        this.devices.forEach(device => {
          let dtime = (device.dt).toString();
          dtime = dtime.substring(11, 19);
          const label = dtime + ' [' + device.sp + ']';

          if (this.markers && this.markers[device.imei]) { // already in
            const marker = this.markers[device.imei];
            marker.setPosition(new google.maps.LatLng(device.loc[0], device.loc[1]));
            marker.setIcon(this.getIcon(device));
            // marker.setLabel(label);

            const labelMarker = marker.get('vehicle_name');
            if (labelMarker.content_ !== device.vehicle) { // device name has changed.
              // labelMarker.setContent('<div style="margin: 10px 5px 0px 0px;">' + device.vehicle + '</div>');
              labelMarker.setMap(null);
              const vehicleName = new InfoBox({latlng: new google.maps.LatLng(device.loc[0], device.loc[1]),
                map: this.map, content: device.vehicle});
              marker.set('vehicle_name', vehicleName);
            }
            labelMarker.setPosition(new google.maps.LatLng(device.loc[0], device.loc[1]));

            const infoWindow = marker.get('infoWindow'); // infoWindow opened marker has one, others have null.
            if (infoWindow) {
              infoWindow.setContent(this.createInfoWindowContent(device));
            }
            // const infoWindow = new google.maps.InfoWindow({
            //   content: this.createInfoWindowContent(device)
            // });
            // infoWindow.setContent(this.createInfoWindowContent(device));
            // infoWindow.open(this.map, marker);

            // marker.setIcon({
            //   url: './assets/images/' + device.icon + '/' +
            //     this.statusColorPipe.transform(device.status, device.sp, this.minMovingSpeed, device.speed_limit)
            //     + '/' + this.getRotation(device.di) + '.svg',
            //   size: new google.maps.Size(33, 33),
            //   // origin: new google.maps.Point(0, 10),
            //   anchor: new google.maps.Point(5, 0),
            //   scaledSize: new google.maps.Size(33, 33),
            //   // scale: 4,
            // });
          } else { // New marker
            // let mapForMarkers = this.map;
            // if (this.isPlayMode) {
            //   mapForMarkers = null;
            // }
            const marker = new google.maps.Marker({
              map: this.map,
              title: device.vehicle,
              position: new google.maps.LatLng(device.loc[0], device.loc[1] ),
              icon: this.getIcon(device),
            });

            // marker.setLabel(label);
            marker.set('imei', device.imei);

            // const infoWindow = new google.maps.InfoWindow({
            //   content: this.createInfoWindowContent(device)
            // });

            // infoWindow.addListener('domready', () => {
            //   const geoLabel = document.getElementsByClassName('geo-label')[0];
            //     geoLabel.addEventListener('click', () => {
            //     this.copyText(geoLabel.innerHTML);
            //   });
            // });

            // marker.set('infoWindow', infoWindow);

            const vehicleName = new InfoBox({latlng: new google.maps.LatLng(device.loc[0], device.loc[1]),
              map: this.map, content: device.vehicle});
            marker.set('vehicle_name', vehicleName);

            // zoom to selected device
            google.maps.event.addListener(marker, 'click', () => {
              this.map.panTo(marker.getPosition());
              // console.log('zoom' + this.map.getZoom());

              if (this.map.getZoom() < MAP_LOW_ZOOM_LEVEL) {
                this.map.setZoom(MAP_ZOOM_LEVEL);
              }
              this.setSelectedDevice(this.filterDeviceByIMEI(marker.get('imei')));
              // this.showInfoWindow(marker, device);
              this.showInfoWindow(marker, this.filterDeviceByIMEI(marker.get('imei')));

              // const infoWindowMarker = marker.get('infoWindow');
              // infoWindowMarker.open(this.map, marker);
            });

            this.markers[device.imei] = marker;
          }
        });

        // this.marker.setLabel(label);

        // setTimeout(() => {
        //   console.log('announcinng: ' + this.device.imei);
          // this.deviceService.announceMission(this.device.imei);
          // this.deviceService.changeMessage('new im');

        // }, 3000);
      });

  }

  private filterDeviceByIMEI(imei: string) {
    return this.devices.filter(d => d.imei === imei)[0];
    // return this.devices.filter(d => d.imei === imei);
  }

  getIcon(device: Device): any {
    if (device.icon !== 'arrow') {
      // const rotation = this.getRotation(device.di);
      const rotation = 90;
      let iconSize = 33;
      if (rotation % 2 !== 0 && device.icon === 'bike') {
        iconSize = 24;
      }
      return {url: './assets/images/' + device.icon + '/' +
      // return {url: './assets/images/bike/' +
      // return {url: './assets/test/' + device.icon + '/' +
      this.statusColorPipe.transform(device.status, device.sp, this.minMovingSpeed, device.speed_limit)
      + '/' + this.getRotation(device.di) + '.svg',
        // + '/90.svg',

        // url: './assets/images/car/running/0.svg',
        // size: new google.maps.Size(33, 33),
        size: new google.maps.Size(iconSize, iconSize),
        // origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(17, 5),
        // scaledSize: new google.maps.Size(33, 33)
        scaledSize: new google.maps.Size(iconSize, iconSize)
      };
    } else {
      let color = this.statusColorPipe.transform(device.status, device.sp, this.minMovingSpeed, device.speed_limit);
      if (color === 'online') {
        color = '#0399BC';
      } else if (color === 'offline') {
        color = '#607D8B';
        // color = '#68768A';
      } else if (color === 'running') {
        color = '#0B8B44';
      } else if (color === 'alarm') {
        color = '#EF4438';
      }

      return {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 4,
        fillColor: color,
        fillOpacity: 0.9,
        strokeWeight: 1,
        rotation: device.di};
    }
  }

  getRotation(rotation: number): number {
    if (rotation < 23 || rotation >= 340) {
      return 0;
    } else if (rotation >= 23 && rotation < 68) {
      return 45;
    } else if (rotation >= 68 && rotation < 110) {
      return 90;
    } else if (rotation >= 110 && rotation < 160) {
      return 135;
    } else if (rotation >= 160 && rotation < 205) {
      return 180;
    } else if (rotation >= 205 && rotation < 250) {
      return 225;
    } else if (rotation >= 250 && rotation < 290) {
      return 270;
    } else if (rotation >= 290 && rotation < 340) {
      return 315;
    }
  }

  private createInfoWindowContent(device: Device) {
    const geo = device.loc[0] + ',' + device.loc[1];
    const contents = `<div class="info-container">
                        <p class="vehicle-name">Vehicle: <span>${device.vehicle}</span></p> <hr class="infow-hr"/>
                        <div>
                          <div class="speed-container"><label>speed: </label><span class="info">${device.sp}</span> Km/h</div>
                          <div class="status-container">
                            <div class="status-circle bg-${this.statusColorPipe.transform(device.status,
                              device.sp, this.minMovingSpeed, device.speed_limit)}"></div>
                            <div class="status-label color-${this.statusColorPipe.transform(device.status,
                              device.sp, this.minMovingSpeed, device.speed_limit)}">
                               ${this.statusColorPipe.transform(device.status, device.sp, this.minMovingSpeed, device.speed_limit)}
                            </div>
                          </div>
                        </div>
                        <div class="alarms color-alarm">
                          <p>${this.getAlarms(device)}</p>
                        </div>
                        <p>time: <span>${this.formatDate(device.dt)}</span></p>
                        <p>geo : <span class="geo-label">${device.loc[0]}, ${device.loc[1]}</span></p>
                        <hr class="infow-hr"/>
                      </div>`;

    const div = document.createElement('div');

    this.zone.run(() => {
      const compFactory = this.resolver.resolveComponentFactory(InfoLinksComponent);
      this.compRef = compFactory.create(this.injector);

      this.appRef.attachView(this.compRef.hostView);

      div.innerHTML = contents;
      div.appendChild(this.compRef.location.nativeElement);
    });
    // contents.appendChild(div);
    // return contents;
    return div;
  }

  private formatDate(date) {
    date = new Date(date);
    const dateString =
      date.getUTCFullYear() + '-' +
      ('0' + (date.getUTCMonth() + 1)).slice(-2) + '-' +
      ('0' + date.getUTCDate()).slice(-2) + ' ' +
      ('0' + date.getUTCHours()).slice(-2) + ':' +
      ('0' + date.getUTCMinutes()).slice(-2) + ':' +
      ('0' + date.getUTCSeconds()).slice(-2);
    return dateString;
  }

  private getAlarms(device) {
    let alarms = '';
    if (device.status === 'alarm') {
      alarms += 'Alarm ';
    }
    if (device.sp > device.speed_limit) {
      alarms += '- Overspeed ';
    }

    if (device.status_codes && device.status_codes.battery === 1) {
      alarms += '- Power Cut';
    }
    return alarms;
  }

  copyText(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  private test() {
    console.log('click test');
  }

  // filterListByImei(id) {
  //   return this.markers.filter(x => x.status === id);
  // }



}

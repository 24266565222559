import { Component, OnInit } from '@angular/core';
import {DeviceService} from '../device.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {
  vechicleName = '';
  vechicleAddress = '';
  isVehicleSelected = false;
  private geocoder: google.maps.Geocoder;

  constructor(private deviceService: DeviceService) {
    this.geocoder = new google.maps.Geocoder();
  }

  ngOnInit() {
    this.checkSelectedDevice();
  }

  checkSelectedDevice() {
    this.deviceService.getSelectedDevice().subscribe(device => {
      if (device) {
        this.vechicleName = device.vehicle;
        this.vechicleAddress = device.loc[0] + ', ' + device.loc[1];
        this.isVehicleSelected = true;
        // this.getAddress(device.loc);

      } else {
        this.isVehicleSelected = false;
      }
    });
  }

  getAddress(loc: [number, number]) {
    let city = '';
    const request = {
      location: new google.maps.LatLng(loc[0], loc[1]),
    };

    this.geocoder.geocode(request, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results && results[0] != null) {
          // city = results[0].address_components[results[0]
          //   .address_components.length - 3].short_name;

          city = results[0].address_components[1].short_name; // Cotta Road.
          // city = results[0].address_components[2].short_name; // Colombo 8
          // city = results[0].address_components[3].short_name; // Colombo
          // city = results[0].formatted_address;
          this.vechicleAddress = city;
          console.log(city);
        } else {
          city = 'No address available';
          console.log(city);
          // alert("No address available");
        }
      } else {
        console.log('can not find address');
      }

      // return city;
    });
  }
}

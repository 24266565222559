import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  message: string;

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  loginSubscription: Subscription;
  loginFailed = false;

  constructor(public authService: AuthService, public router: Router, private formBuilder: FormBuilder) {
    this.setMessage();
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.onChanges();
  }

  onChanges() {
    this.loginForm.valueChanges.subscribe(() => {
      this.loginFailed = false;
    });
  }

  setMessage() {
    this.message = 'Logged ' + (this.authService.isLoggedIn ? 'in' : 'out');
  }

  get f() { return this.loginForm.controls; }

  login() {
    this.message = 'Trying to log in ...';

    this.authService.login_o().subscribe(() => {
      this.setMessage();
      if (this.authService.isLoggedIn) {
        // Get the redirect URL from our auth service
        // If no redirect has been set, use the default
        localStorage.setItem('isLoggedIn', 'true');
        const redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/live-monitor';

        // Redirect the user
        this.router.navigate([redirect]);
      }
    });
  }

  logout() {
    this.authService.logout();
    this.setMessage();
  }

  onSubmit() {
    this.submitted = true;
    this.loginFailed = false;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    }

    this.loading = true;
    this.loginSubscription = this.authService.login(this.f.username.value, this.f.password.value)
      // .pipe(first())
      .subscribe(
        user => {
          if (user) {
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('currentUser', JSON.stringify(user));
            const redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/live-monitor';
            this.router.navigate([redirect]);
          } else {
              console.log('no such user!');
            this.loading = false;
            this.loginFailed = true;
          }

        },
        error => {
          // this.alertService.error(error);
          console.log('error finding user!');
          this.loading = false;
          this.loginFailed = true;
        });
  }

  ngOnDestroy() {
    // console.log('login destroied.');
    this.loginSubscription.unsubscribe();
  }

}

import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {delay, tap} from 'rxjs/operators';
import {User} from '../model/User';
import {HttpClient} from '@angular/common/http';
import {API} from '../../const';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = false;
  isUser = true; // not admin
  // uri = 'http://localhost:4000';
  // uri = 'http://3.0.180.122:4000';
  // uri = 'http://13.250.122.31:4000';

  uri = API;
  isAdmin = true;

  authStatus = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  login_o(): Observable<boolean> {
    return of(true).pipe(
      delay(1000),
      tap(val => {this.isLoggedIn = true;
      this.setLoginStatus(true);
      })
    );
  }

  checkRole() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.role === 'business') {
      this.isAdmin = false;
    }
    return this.isAdmin;
  }

  login(username: string, password: string): Observable<User> {
    // if (username === password) {
    // return of(true).pipe(
    //   delay(1000),
    //   tap(val => {
    //     this.isLoggedIn = true;
    //     this.setLoginStatus(true);
    //   })
    // );
    // } else {
    //   // else return 400 bad request
    //   return throwError({ error: { message: 'Username or password is incorrect' } });
    // }

    const params = {
      username: username,
      password: password
    };
    return this.http.post<User>(`${this.uri}/user/auth`, params).pipe(
      tap(user => {
        if (user.role !== 'superadmin') {
          this.isAdmin = false;
        } else {
          this.isAdmin = true;
        }
      })
    );
    // const user = this.http.post<User>(`${this.uri}/user/auth`, params);
    //
    // if (user.username === username) {
    //   return of(true);
    // } else {
    //   console.log('no such user!');
    //     return throwError({ error: { message: 'Username or password is incorrect' } });
    // }
  }

  logout(): void {
    // this.isLoggedIn = false;
    localStorage.setItem('isLoggedIn', 'false');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('active_biz');
    this.setLoginStatus(false);
  }

  setLoginStatus(status: boolean) {
    this.isLoggedIn = status;
    this.authStatus.next(status);
  }

  getLoginStatus(): Observable<boolean> {
    return this.authStatus.asObservable();
  }

  resetPassword(username: string, password: string, newPassword: string): Observable<User> {
    const params = {
      username: username,
      password: password,
      newPassword: newPassword
    };
    return this.http.post<User>(`${this.uri}/user/resetpw`, params).pipe(
      tap(user => {

      })
    );
    // const user = this.http.post<User>(`${this.uri}/user/auth`, params);
    //
    // if (user.username === username) {
    //   return of(true);
    // } else {
    //   console.log('no such user!');
    //     return throwError({ error: { message: 'Username or password is incorrect' } });
    // }
  }

}

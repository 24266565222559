import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Business} from '../model/Business';
import {catchError, tap} from 'rxjs/operators';
import {DeviceService} from './device.service';
import {API} from '../../const';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  // uri = 'http://localhost:4000';
  // uri = 'http://3.0.180.122:4000';
  // uri = 'http://13.250.122.31:4000';
  uri = API;

  constructor(private http: HttpClient) { }

  getBusinessTree(): Observable<Business[]> {
    return this.http.get<Business[]>(`${this.uri}/business/tree`).pipe(
      // tap(businesses => {
      //   businesses.forEach( business => {
      //     console.log(business._id);
      //   });
      // }),
      catchError(this.handleError('getBusiness List', []))
    );
  }

  // setSelectedBusiness(business: Business) {
  //   this.deviceService.setSelectedBusiness(business);
  // }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    // this.messageService.add(`HeroService: ${message}`);
  }
}

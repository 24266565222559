import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LiveMonitorComponent} from './monitor/live-monitor/live-monitor.component';
import {TestComponent} from './monitor/test/test.component';
import {AuthGuard} from './auth/auth.guard';
import {LoginComponent} from './auth/login/login.component';

const routes: Routes = [
  {path: 'live-monitor', component: LiveMonitorComponent, canActivate: [AuthGuard]},
  // {path: 'live-monitor', component: LiveMonitorComponent},
  {path: 'test',
  component: TestComponent
    , canActivate: [AuthGuard],
  // outlet: 's'
  },
  {path: 'login', component: LoginComponent},
  // {path: 'reporting', loadChildren: 'src/app/reporting/reporting.module#ReportingModule'},
  {path: 'report', loadChildren: './reporting/reporting.module#ReportingModule'},
  {path: 'settings', loadChildren: './settings/settings.module#SettingsModule'},
  {path: '', redirectTo: 'live-monitor', pathMatch: 'full'},
  // { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statuscase'
})
export class StatuscasePipe implements PipeTransform {

  transform(status: string, speed: number, move_speed: number): string {
    return (speed >= move_speed  && status !== 'offline') ? speed + ' Km/h' : status;
  }

}

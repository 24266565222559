import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statuscolor'
})
export class StatuscolorPipe implements PipeTransform {

  // transform(value: any, args?: any): any {
  transform(status: string, speed: number, move_speed: number, speed_limit: number): string {
    if (status === 'offline') {
      return 'offline';
    } else if (status === 'alarm') {
      return 'alarm';
    } else if (speed < move_speed) {
      return 'online';
    } else if (speed >= move_speed && speed <= speed_limit ) {
      return 'running';
    } else { // online but overspeed.
      return 'alarm';
    }

  }

}

import { Component, OnInit } from '@angular/core';
import {Device} from '../../model/Device';
import {DeviceService} from '../device.service';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent, MatDialog} from '@angular/material';
import {DeviceSettingsDialogComponent} from '../device-settings-dialog/device-settings-dialog.component';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.css']
})
export class DeviceListComponent implements OnInit {
  devices: Device[] = [];
  onlineDevices: Device[];
  offlineDevices: Device[];
  selectedDevice: Device;
  selectedDeviceId: string;
  minMovingSpeed: number;
  myControl = new FormControl();
  filteredOptions: Observable<Device[]>;

  constructor(private deviceService: DeviceService, public dialog: MatDialog) {
    this.minMovingSpeed = 7;
  }

  ngOnInit() {
    this.deviceService.getDevices().subscribe(devices => {this.devices = devices;

      this.onlineDevices = this.filterListByStatus('online');
      this.offlineDevices = this.filterListByStatus('offline');
    });

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith<string | Device>(''),
        map(value => typeof value === 'string' ? value : value.vehicle),
        map(name => name ? this._filter(name) : this.devices.slice())
      );

    this.getSelectedDevice();
  }


  displayFn(device?: Device): string | undefined {
    // if (device) {
    //   this.setSelectedDevice(device);
    // }
    return device ? device.vehicle : undefined;
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.setSelectedDevice(event.option.value);
  }

  private _filter(vehicle: string): Device[] {
    const filterValue = vehicle.toLowerCase();

    return this.devices.filter(device => device.vehicle.toLowerCase().indexOf(filterValue) > -1);
  }

  getSelectedDevice() {
    this.deviceService.getSelectedDevice().subscribe( device => {
      if (device) {
        this.selectedDevice = device;
        this.selectedDeviceId = device.imei;
      }
    });
  }

  setSelectedDevice (device: Device) {
    this.selectedDevice = device;
    this.selectedDeviceId = device.imei;
    this.deviceService.setSelectedDevice(device);
  }

  showDeviceStatus(device: Device) {
    this.deviceService.setStatusShowDevice(device);
  }

  openSettings() {
    const dialogRef = this.dialog.open(DeviceSettingsDialogComponent, {
      width: '250px',
      data: {device: this.filterDeviceByIMEI(this.selectedDeviceId)}
    });

    dialogRef.afterClosed().subscribe(result => {
      const index = this.devices.indexOf(this.filterDeviceByIMEI(result.imei));
      this.selectedDevice = result;
      this.devices[index] = result;
    });
  }

  filterListByStatus(status) {
    return this.devices.filter(x => x.status === status);
  }

  play() {
    this.deviceService.setPlayMode(true);
  }

  private filterDeviceByIMEI(imei: string) {
    return this.devices.filter(d => d.imei === imei)[0];
    // return this.devices.filter(d => d.imei === imei);
  }

}

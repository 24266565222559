import {Component, OnDestroy, OnInit} from '@angular/core';
import {DeviceService} from '../device.service';
import {Device} from '../../model/Device';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit, OnDestroy {
  device: Device;
  subscription: Subscription;
  imei = 'start';

  constructor(private deviceService: DeviceService) {
    // this.subscription = deviceService.missionAnnounced$.subscribe(
    //   imei => {
    //     this.imei = imei;
    //   });
  }

  ngOnInit() {
      // this.subscription = this.deviceService.missionAnnounced.subscribe(
      //   imei => {
      //     this.imei = imei;
      //   });

    // this.deviceService.currentMessage.subscribe(message => this.imei = message);
    // this.deviceService.getDevices().subscribe(message => this.device = message);

  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    // this.subscription.unsubscribe();
  }

}

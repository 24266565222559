/// <reference types="@types/googlemaps" />
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {Device} from '../model/Device';
import {LiveMonitorComponent} from './live-monitor/live-monitor.component';
import {catchError, tap} from 'rxjs/operators';
import {Business} from '../model/Business';
import {Geo} from '../model/Geo';
import {Parking} from '../model/Parking';
import {User} from '../model/User';
import {API} from '../../const';

@Injectable({
  providedIn: 'root'
})
// @Injectable()
export class DeviceService {

  // uri = 'http://localhost:4000';
  // uri = 'http://3.0.180.122:4000';
  // uri = 'http://13.250.122.31:4000';
  // localUri = 'http://localhost:4000';
  uri = API;


  // devices: Device[];

  // BehaviourSubject return current/initial value on subscribe, Subject return value on next.
  private missionAnnouncedSource = new BehaviorSubject<Device[]>([]);
  private selectedBusinessSource = new Subject<Business>();
  private selectedBusiness: Business;
  private selectedDevice = new BehaviorSubject<Device>(null);
  private statusRequestedDevice = new Subject<Device>();
  minMovingSpeed = 7;

  private user: User;

  map: google.maps.Map;
  private playMode = new BehaviorSubject<boolean>(false);
  // Observable string streams
  // missionAnnounced$ = this.missionAnnouncedSource.asObservable();

  // private messageSource = new BehaviorSubject('default message');
  // currentMessage = this.messageSource.asObservable();

  constructor(private http: HttpClient) {
    this.selectedBusiness = new Business();

    if (localStorage.getItem('isLoggedIn') === 'true') {
      this.user = JSON.parse(localStorage.getItem('currentUser'));
      if (this.user.business_id !== null) {
        this.selectedBusiness._id = this.user.business_id;
      } else {
        this.selectedBusiness._id = '0';
      }
    }
    // this.selectedBusiness._id = '5bd69f33f2a80eb4cf41cfaf';
  }


  getStatus(): Observable<Device[]> {
    return this.http.get<Device[]>(`${this.uri}/live/${this.selectedBusiness._id}`).pipe(
      tap(devices => {this.announceMission(devices); }),
      catchError(this.handleError('getDeviceStatus', []))
    );
    // return this.device;
  }

  // getDevice(): Device[]{
  //   return this.devices;
  // }

  announceMission(devices: Device[]) {
    // devices[0].dt = new Date();
    // devices[0].speed_limit = 100;
    this.missionAnnouncedSource.next(devices);
  }

  getDevices(): Observable<Device[]> {
    return this.missionAnnouncedSource.asObservable();
  }

  setSelectedBusiness(business: Business) {
    this.selectedBusiness = business;
    this.selectedBusinessSource.next(business);
    // this.getStatus();

    this.user = JSON.parse(localStorage.getItem('currentUser'));
    if (this.user.business_id === null) { // superadmin
      localStorage.setItem('active_biz', this.selectedBusiness._id); // for superadmin to monitor other users.
    }
  }

  getSelectedBusiness(): Business {
    return this.selectedBusiness;
  }

  getChangedBusiness(): Observable<Business> {
    return this.selectedBusinessSource.asObservable();
  }

  setSelectedDevice(device: Device) {
    console.log('setting selected device!');
    this.selectedDevice.next(device);
  }

  getSelectedDevice(): Observable<Device> {
    return this.selectedDevice.asObservable();
  }

  setStatusShowDevice(device: Device) {
    this.statusRequestedDevice.next(device);
  }

  getStatusShowDevice(): Observable<Device> {
    return this.statusRequestedDevice.asObservable();
    // return this.selectedDevice.asObservable();
  }

  getPlayPath(id: string, start: number, end: number): Observable<Geo[]> {
    // console.log('start :' + start.getTime() + ' end :' + end.getTime());
    const params = {
      id: id,
      start: start, // time in milliseconds
      end: end
    };
    return this.http.post<Geo[]>(`${this.uri}/play`, params);
  }

  getParkings(id: string, start: number, end: number, duration: number): Observable<Parking[]> {
    const params = {
      id: id,
      start: start, // time in milliseconds
      end: end,
      duration: duration
    };
    return this.http.post<Parking[]>(`${this.uri}/getparkings`, params);
  }


  playPathByDay(observer) {
    const startDate = new Date(2018, 11, 24);
    const endDate = new Date(2018, 11, 27);
    const seq = [1, 2, 3];
    let timeoutId;

    function  doSequence() {
      console.log('test fn');

      timeoutId = setTimeout(() => {
        // observer.next(arr[idx]);

        // const startTime = startDate.getTime();
        startDate.setDate(startDate.getDate() + 1);
        //
        if (startDate < endDate) {
          const endTime = startDate.getTime();

          const params = {
            day: startDate.getDate()
          };

          const res = this.http.post(`${this.uri}/testday`, params);

          observer.next(res);
          // observer.next(endTime);
          doSequence();
        } else {
          const endTime = endDate.getTime();
          observer.next(endTime);
          observer.complete();
        }

        // if (idx === arr.length - 1) {
        //   observer.complete();
        // } else {
        //   doSequence(arr, ++idx);
        // }
      }, 1000);
    }

    // doSequence(seq, 0);
    doSequence();

    // Unsubscribe should clear the timeout to stop execution
    return {unsubscribe() {
        clearTimeout(timeoutId);
      }};
  }

  getPlayMode(): Observable<boolean> {
    return this.playMode.asObservable();
  }

  setPlayMode(value: boolean) {
    this.playMode.next(value);
  }

  // changeMessage(message: string) {
  //   this.messageSource.next(message);
  // }

  public formatDate(date) {
    date = new Date(date);
    const dateString =
      date.getUTCFullYear() + '-' +
      ('0' + (date.getUTCMonth() + 1)).slice(-2) + '-' +
      ('0' + date.getUTCDate()).slice(-2) + ' ' +
      ('0' + date.getUTCHours()).slice(-2) + ':' +
      ('0' + date.getUTCMinutes()).slice(-2) + ':' +
      ('0' + date.getUTCSeconds()).slice(-2);
    return dateString;
  }

  public getAlarms(geo: Geo, device: Device) {
    let alarms = '';
    if (geo.status === 'alarm') {
      alarms += 'Alarm';
    }
    if (geo.sp > device.speed_limit) {
      alarms += 'Overspeed';
    }
    return alarms;
  }

  public getDeviceWithSettings (id: string) {
    return this.http.get<Device>(`${this.uri}/getdevicewithsettings/${id}`);
  }

  public updateDeviceSettings(device: Device) {
    return this.http.post<Device>(`${this.uri}/updateDeviceSettings/${device._id}`, device);
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    // this.messageService.add(`HeroService: ${message}`);
  }
}

import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {DeviceService} from '../../../monitor/device.service';
import {AuthService} from '../../../auth/auth.service';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-navi',
  templateUrl: './navi.component.html',
  styleUrls: ['./navi.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NaviComponent implements OnInit {
  panelOpenState: boolean;
  private isAdmin: boolean;
  @ViewChild('drawer') public drawer;
  @ViewChild('mainTabGroup') mainTabGroup;
  isPlayMode = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver, private router: Router,
              private deviceService: DeviceService, public authService: AuthService) {}

  ngOnInit() {
    // check whether user or admin login.
    this.isAdmin = this.authService.checkRole();
    this.checkPlayMode();
  }

  checkPlayMode() {
    this.deviceService.getPlayMode().subscribe(playMode => {
      this.isPlayMode = playMode;
        if (playMode) {
          this.drawer.close();
        } else {
          this.drawer.open();
        }
    });
  }

  gotoLiveMonitor() {
    console.log('router call');
    this.router.navigateByUrl('/live-monitor');
  }

  test($event) { // :TODO rename me please :/
    const tabIdx = $event.index;
    console.log('test navi click.' + tabIdx);

    if (tabIdx === 0) {
      this.router.navigate(['/live-monitor']);
      // this.router.navigate(['/live-monitor', params]);
    }
  }

  onTabClick(event: MouseEvent) {
    console.log('tab clicked: ' + this.mainTabGroup.selectedIndex);

    if (this.isPlayMode && this.mainTabGroup.selectedIndex === 0) {
      let el = event.srcElement;
      const attr = el.attributes.getNamedItem('class');
      if (attr && attr.value.indexOf('mat-tab-label') >= 0) {
        el = el.parentElement;
        console.log('label clicked');

        this.deviceService.setPlayMode(false);
      }
      // this.router.navigate(['/live-monitor']);
    }
  }

  playbackReport() {
    this.router.navigateByUrl('/live-monitor');
    this.deviceService.setPlayMode(true);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}

import {Component, OnInit} from '@angular/core';
import {AuthService} from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'gpsfront';
  isLogin = true;

  constructor (private authService: AuthService) {}

  ngOnInit() {
    // this.isLogin = !this.authService.isLoggedIn;

    this.authService.getLoginStatus().subscribe(status => {
      if (status) {
        this.isLogin = false;
      }
    });
  }
}

import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Device} from '../model/Device';
import {HttpClient} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {Business} from '../model/Business';
import {ReportingModule} from './reporting.module';
import {Mileage} from '../model/Mileage';
import {Geo} from '../model/Geo';
import {User} from '../model/User';
import {API} from '../../const';

@Injectable({
  providedIn: 'root'
  // providedIn: ReportingModule
})
export class ReportService {
  // uri = 'http://localhost:4000';
  // uri = 'http://3.0.180.122:4000';
  // uri = 'http://13.250.122.31:4000';
  uri = API;

  private selectedBusiness: Business;
  private user: User;
  // private allDevices = new BehaviorSubject<Device[]>([]);

  constructor(private http: HttpClient) {
    this.selectedBusiness = new Business();
    // this.selectedBusiness._id = '5bd69f33f2a80eb4cf41cfaf';
    if (localStorage.getItem('isLoggedIn') === 'true') {
      this.user = JSON.parse(localStorage.getItem('currentUser'));
      if (this.user.business_id !== null) {
        this.selectedBusiness._id = this.user.business_id;
      } else {
        this.selectedBusiness._id = '0';
      }
    }
  }

  getDeviceList(): Observable<Device[]> {
    if (localStorage.getItem('active_biz') !== null) {
      this.selectedBusiness._id = localStorage.getItem('active_biz');
    }

      return this.http.get<Device[]>(`${this.uri}/live/${this.selectedBusiness._id}`);
  }

  // getMileage(id: string, start: number, end: number): Observable<Mileage[]> {
  //   const params = {
  //     id: id,
  //     start: start, // time in milliseconds
  //     end: end
  //   };
  //   console.log('post : ' + id);
  //
  //   return this.http.post<Mileage[]>(`${this.uri}/getmileagebyday`, params);
  // }

  getMileage(id: string, start: number, end: number): Observable<Mileage[]> {
    // console.log('start :' + start.getTime() + ' end :' + end.getTime());
    const params = {
      id: id,
      start: start, // time in milliseconds
      end: end
    };

    return this.http.post<Mileage[]>(`${this.uri}/getmileagebyday`, params);
  }
}

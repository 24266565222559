import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Device} from '../../model/Device';
import {DeviceService} from '../device.service';

export interface DialogData {
  device: Device;
}

@Component({
  selector: 'app-device-settings-dialog',
  templateUrl: './device-settings-dialog.component.html',
  styleUrls: ['./device-settings-dialog.component.css']
})
export class DeviceSettingsDialogComponent implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<DeviceSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private deviceService: DeviceService) {}

    onNoClick() {
      this.dialogRef.close();
    }

    ngOnInit() {
      this.getDeviceWithSettings();
    }

    getDeviceWithSettings() {
      this.deviceService.getDeviceWithSettings(this.data.device._id).subscribe(device => {
        this.data.device = device;
        console.log('Full device: ' + this.data.device);
      });
    }

    saveDevice() {
      this.deviceService.updateDeviceSettings(this.data.device).subscribe(device => {
        this.data.device = device;
      });
    }
}

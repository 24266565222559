import { Component, OnInit } from '@angular/core';
import {DeviceService} from '../device.service';
import {Geo} from '../../model/Geo';
import {LiveMonitorComponent} from '../live-monitor/live-monitor.component';
import {DeviceSettingsDialogComponent} from '../device-settings-dialog/device-settings-dialog.component';
import {Device} from '../../model/Device';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-info-links',
  templateUrl: './info-links.component.html',
  styleUrls: ['./info-links.component.css']
})
export class InfoLinksComponent implements OnInit {
  selectedDevice: Device;

  constructor(private deviceService: DeviceService, public dialog: MatDialog) { }

  ngOnInit() {
    this.getSelectedDevice();
  }

  play() {
    console.log('playback');
    this.deviceService.setPlayMode(true);
  }

  getSelectedDevice() {
    this.deviceService.getSelectedDevice().subscribe(device => {
      this.selectedDevice = device;
    });
  }

  openSettings() {
    const dialogRef = this.dialog.open(DeviceSettingsDialogComponent, {
      width: '250px',
      data: {device: this.selectedDevice}
    });

    dialogRef.afterClosed().subscribe(result => {
      // const index = this.devices.indexOf(this.filterDeviceByIMEI(result.imei));
      this.selectedDevice = result;
      // this.devices[index] = result;
    });
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NaviComponent } from './shared/components/navi/navi.component';
import { LayoutModule } from '@angular/cdk/layout';
import {
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatTabsModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatTreeModule,
  MatAutocompleteModule,
  MatSelectModule, MatSliderModule, MatDialogModule,
} from '@angular/material';
import { LiveMonitorComponent } from './monitor/live-monitor/live-monitor.component';
import { TestComponent } from './monitor/test/test.component';
import {DeviceService} from './monitor/device.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { DeviceListComponent } from './monitor/device-list/device-list.component';
import { StatuscasePipe } from './monitor/statuscase.pipe';
import { StatuscolorPipe } from './monitor/statuscolor.pipe';
import { BusinessTreeComponent } from './monitor/business-tree/business-tree.component';
import {BusinessService} from './monitor/business.service';
import { InfoLinksComponent } from './monitor/info-links/info-links.component';
import { LoginComponent } from './auth/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PlayControlsComponent } from './monitor/play-controls/play-controls.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import { DeviceSettingsDialogComponent } from './monitor/device-settings-dialog/device-settings-dialog.component';
import {ReportService} from './reporting/report.service';
import {SharedModule} from './shared/shared/shared.module';
import { DurationPipe } from './shared/pipes/duration.pipe';
import { BlankComponent } from './shared/components/blank/blank.component';
import {JwtInterceptor} from './helper/jwt.interceptor';
import { AddressComponent } from './monitor/address/address.component';
// import {DefaultUrlSerializer, UrlSerializer, UrlTree} from '@angular/router';
//
// export class MyUrlSerializer extends DefaultUrlSerializer implements UrlSerializer  {
//   /** Converts a `UrlTree` into a url */
//   serialize(tree: UrlTree): string {
//     return super.serialize(tree).replace(/\((((?!(\/\/)).)*)\)/g, '$1');
//   }
// }


@NgModule({
  declarations: [
    AppComponent,
    NaviComponent,
    LiveMonitorComponent,
    TestComponent,
    DeviceListComponent,
    StatuscasePipe,
    StatuscolorPipe,
    BusinessTreeComponent,
    InfoLinksComponent,
    LoginComponent,
    PlayControlsComponent,
    DeviceSettingsDialogComponent,
    BlankComponent,
    AddressComponent,
    // PasswordValidatorDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatTreeModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatSliderModule,
    MatDialogModule,
    SharedModule
  ],
  entryComponents: [
    InfoLinksComponent,
    DeviceSettingsDialogComponent
  ],
  // providers: [DeviceService, BusinessService],
  providers: [DeviceService, BusinessService, ReportService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }],
  // providers: [{ provide: UrlSerializer, useClass: MyUrlSerializer }],
  bootstrap: [AppComponent]
})
export class AppModule { }

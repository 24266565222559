import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(seconds: number): string {
    let duration = '';

    const hrs = parseInt((seconds / 3600) + '', 10);
    duration += (hrs < 10 ? '0' + hrs : hrs) + ':';
    seconds %= 3600;

    const mins = parseInt((seconds / 60) + '', 10);
    duration += (mins < 10 ? '0' + mins : mins) + ':';;
    seconds %= 60;

    duration += (seconds < 10 ? '0' + seconds : seconds);

    return duration;
  }

}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.username) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.username},${currentUser.token}`
        }
      });
    }

    return next.handle(request);
    // return next.handle(request).pipe(
    //   tap(
    //     event => {
    //       //logging the http response to browser's console in case of a success
    //       if (event instanceof HttpResponse) {
    //         console.log("api call success :", event);
    //       }
    //     },
    //     error => {
    //       //logging the http response to browser's console in case of a failuer
    //       if (event instanceof HttpResponse) {
    //         console.log("api call error :", event);
    //       }
    //     }
    //   )
    // );

  }
}
